<template>
	<template v-if="profile && capabilities && modules && localizationData">
		<div
			class="mt-[var(--header-height)] sm:mt-[var(--header-height-sm)] lg:mt-[var(--header-height-lg)]"
			data-test="auth-context-logged-in"
		>
			<AppToasts />
			<AppNotifications />

			<AppHeader />

			<ErrorBoundary>
				<slot />
				<template #error="{ error, clearError }">
					<ErrorPage404
						v-if="error?.statusCode === 404"
						:error="error"
						@clear-error="clearError"
					/>
					<ErrorPage403
						v-else-if="error?.statusCode === 403"
						:error="error"
						@clear-error="clearError"
					/>
					<ErrorPageDefault
						v-else
						:error="error"
						@clear-error="clearError"
					/>
				</template>
			</ErrorBoundary>

			<ModalsContainer />

			<transition name="fade">
				<div
					v-if="profile && profile.isImpersonated"
					class="fixed bottom-8 right-8 z-20 flex gap-3 rounded-full border border-slate-200 bg-white px-5 py-2 shadow-xl"
				>
					<p class="font-medium text-slate-600">
						Du jobber nå som
						<span class="font-semibold text-primary">
							{{ profile.firstName }}
							{{ profile.lastName }}
						</span>
					</p>
				</div>
			</transition>
		</div>
	</template>

	<template v-else>
		<div
			class="flex h-screen w-screen animate-fade-in flex-col items-center justify-center bg-white"
		>
			<AxosLogo class="h-24 fill-primary" />

			<LoadingSpinner />
		</div>
	</template>
</template>

<script lang="ts" setup>
import { HubConnectionState } from '@microsoft/signalr';
import { ModalsContainer } from 'vue-final-modal';
import * as Sentry from '@sentry/vue';

// TODO: Add caching
const { localizationData, suspense } = useLocalizationData();
await suspense();

const { setResourcesData, setLanguageId, missingResources } = useLocalization();
setResourcesData(localizationData.value!);

const { mutateAsync: reportMissingResources } = useMutation({
	mutationFn: (resources: string[]) =>
		$api.reportMissingResources({ resources }),
});
watchDebounced(
	missingResources,
	() => {
		reportMissingResources(missingResources.value);
	},
	{ debounce: 5000 }
);

const { suspense: suspenseNumberings } = useNumberings();
await suspenseNumberings();

const { currentLanguageId } = useAppLanguage();
watch(
	currentLanguageId,
	(newId) => {
		if (newId) {
			setLanguageId(newId);
		}
	},
	{ immediate: true }
);

const { profile } = useProfile();
const { capabilities } = useCapabilities();
const { modules } = useModules();
const { currentClient } = useAuthClient();

watchEffect(() => {
	if (profile.value && currentClient.value) {
		Sentry.setUser({
			id: profile.value.id,
			email: profile.value.email,
			clientId: currentClient.value.id,
			clientName: currentClient.value.name,
		});
	}
});

try {
	const app = useNuxtApp();
	const signalRConnection = app.$signalR;

	if (signalRConnection.state !== HubConnectionState.Connected) {
		signalRConnection
			.start()
			.then(() => console.log('SignalR connection started'))
			.catch((err) => console.error('SignalR connection failed: ', err));
	}
} catch (error) {
	console.error(error);
}
const { unreadCount } = useNotifications();

const favicon = computed(() =>
	unreadCount.value && unreadCount.value > 0
		? '/favicon-unread.svg'
		: '/favicon.svg'
);

// Impersonate
const queryClient = useQueryClient();

const { impersonatingId } = useImpersonate();
watch(impersonatingId, () => {
	setTimeout(() => {
		queryClient.resetQueries();
	}, 500);
});

useFavicon(favicon);

useHead({
	templateParams: {
		site: {
			name: 'Axos',
		},
		separator: '·',
		subPage: null,
		unreadCount: computed(() =>
			unreadCount.value ? `(${unreadCount.value?.toString()})` : null
		),
	},
	titleTemplate: '%unreadCount %subPage %separator %s %separator %site.name',
	meta: [{ name: 'theme-color', content: '#0059ff' }],
	bodyAttrs: {
		class: 'text-slate-800 bg-slate-75',
	},
});
</script>
